html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#pie {
  height: 100vh;
}

#pie * {
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.hover-scale {
  transition: all ease-in-out .4s !important;
}

.hover-scale:hover {
  transform: scale(1.025);
}

@keyframes popout {
  from{transform:scale(0)}
  80%{transform:scale(1.2)}
  to{transform:scale(1)}
}
@-webkit-keyframes popout {
  from{-webkit-transform:scale(0)}
  80%{-webkit-transform:scale(1.2)}
  to{-webkit-transform:scale(1)}
}

@import url(./book-reader.css);
@import url(./mobile-drawer.css);
@import url(./tabs.css);
@import url(./card-image-skeleton.css);
@import url(./toc-drawer.css);
