.toc-drawer-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-size: 22px;
    user-select: none;
    transition: all ease-in-out .3s;
}

.toc-drawer-close-icon {
    cursor: pointer;
    transition: inherit;
}
.toc-drawer-title-text {
    transition: inherit;
}

.toc-drawer-close-icon:hover {
    color: #2196F3;
}

.toc-list-container {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-top: 15px;
    transition: all ease-in-out .3s;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: #2196F3 #cccccc;
    scrollbar-width: thin;
}

.toc-list-container::-webkit-scrollbar {
    width: 7px;
}

.toc-list-container::-webkit-scrollbar-track {
    background-color: #cccccc;
    width: 8px;
}

.toc-list-container::-webkit-scrollbar-thumb {
    background-color: #2196F3;
    border-radius: 50px;
    width: 8px;
}

.toc-list-container::-webkit-scrollbar-thumb:hover {
    background-color: #2280cc;
}

.toc-list-item {
    padding: 10px 20px;
    border-bottom: 1px solid #cccccc;
    cursor: pointer;
    user-select: none;
    transition: inherit;
    font-size: 18px;
}

.toc-list-item:hover {
    color: #2196F3;
    background-color: #dadada;
}

.toc-drawer-title-dark {
    background: #424242;
}

.toc-drawer-title-dark .toc-drawer-title-text {
    color: #b28900;
} 

.toc-drawer-title-dark .toc-drawer-close-icon{
    color: #b28900;
}

.toc-drawer-title-dark .toc-drawer-close-icon:hover {
    color: rgba(178,	137,	0, 0.75);
}

.toc-list-container-dark {
    background: #424242;
    scrollbar-color: #b28900 #2E2E2E;
    scrollbar-width: thin;
}

.toc-list-container-dark::-webkit-scrollbar-track {
    background: #2E2E2E;
}

.toc-list-container-dark::-webkit-scrollbar-thumb {
    background: #b28900;
}

.toc-list-container-dark::-webkit-scrollbar-thumb:hover {
    background: rgba(178,	137,	0, 0.75);
}

.toc-list-container-dark .toc-list-item  {
    color: #b28900;
}

.toc-list-container-dark .toc-list-item:hover {
    background: #2E2E2E;
}