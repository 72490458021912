.tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: '5rem';
    overflow-y: auto;
    overflow-x: hidden;
}

.tabs-pages {
    display: flex;
    justify-content: center;
    padding: 2rem;
    align-items: center;
    background: #ede7f6;
    border: 1px solid #b39ddb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tabs-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow-x: auto;
    overflow-y: visible !important;
    max-width: 100%;
    min-height: 40px;
    scrollbar-color: #b39ddb #884ce4;
    scrollbar-width: thin; 
}

.tabs-tabs::-webkit-scrollbar {
    height: 10px;
}

.tabs-tabs::-webkit-scrollbar-track {
    background: #884ce4;
}

.tabs-tabs::-webkit-scrollbar-thumb {
    background: #b39ddb;
    border-radius: 2px;
}

.tab {
    background: #ede7f6;
    transition: all ease-in-out .2s;
    border: 1px solid #b39ddb;
    padding: 3px 5px;
    user-select: none;
    cursor: pointer;
}
.tab:first-of-type {
    border-top-left-radius: 5px;
}
.tab:last-of-type {
    border-top-right-radius: 5px;
}

.tab:hover {
    padding-bottom: 10px;
    background: #b08ce6;
    color: white;
}

.tab-open {
    padding-bottom: 10px;
    background: #884ce4;
    color: white;
    cursor: default;
}

.tab-open:hover {
    background: #884ce4;
}