.book-reader-container * {
  transition: all ease-in-out 0.3s;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0,0,0,0);
  scrollbar-width: none;
}

.book-reader-container *::-webkit-scrollbar {
  width: 0;
  height: 0;
} 

.book-reader-container *::-webkit-scrollbar-track {
  display: none;
}

.book-reader-container *::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}
.book-reader-buttons-hidden {
  display: 'none' !important
}

.book-reader-dark-mode > div > div {
  background-color: #424242 !important;
}

.book-reader-dark-mode > div > div + div > div:first-of-type {
  background-color: #212121 !important;
}

.book-reader-dark-mode > div > div + div > div:first-of-type > div button {
  border-color: #b28900 !important;
}

.book-reader-dark-mode > div > div button {
  color: #b28900 !important;
}

.book-reader-dark-mode > div > div button span {
  background-color: #b28900 !important;
}

.book-reader-dark-mode + .book-reader-menu > div > select {
  color: #b28900 !important;
}

.book-reader-dark-mode + .book-reader-menu > div::before {
  border-color: #b28900 !important;
}

.book-reader-dark-mode
  + .book-reader-menu
  > div
  > select
  option {
  color: #b28900 !important;
  background-color: #424242 !important;
}

.book-reader-dark-mode
  + .book-reader-menu
  > div
  > select
  + svg {
  color: #b28900 !important;
}
.react-pdf__Page > canvas {
  max-width: 100%;
}

.book-reader-menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(100%, 0);
  right: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 50px;
  padding-right: 0;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
  z-index: 99;
  overflow: hidden;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}

.book-reader-menu-open {
  transform: translate(0, 0);
  visibility: visible;
}

.book-reader-back-to-home-link {
  color: black;
  transition: color ease-in-out 0.3s;
  display: flex;
  align-items: center;
  margin-right: auto;
  text-decoration: none;
}

.book-reader-back-to-home-link:hover {
  color: rgb(21, 105, 214);
}

.book-reader-font-buttons-dark {
    background-color: #535353 !important;
}

.book-reader-font-buttons-dark:hover {
    background-color: #212121 !important;
}

.book-reader-back-to-home-link-dark {
    color: #b28900;
}

.book-reader-back-to-home-link-dark:hover {
  color: rgba(178,	137,	0, 0.75);
}

.book-reader-page-info {
    position: fixed;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 0 5px;
    z-index: 99;
    font-size: 12px;
}

.book-reader-page-info-dark {
    color: #b28900;
} 

@media (max-width: 1200px) {
  .book-reader-menu  {
    left: 0;
    border-bottom-left-radius: 0;
    padding-left: 25px;
    padding-right: 25px;
  }

  .book-reader-back-to-home-link > div {
    font-size: 14px !important;
  }
  .book-reader-back-to-home-link > svg {
    font-size: 14px !important;
  }

  .book-reader-dark-mode + .book-reader-menu  {
    background: #212121 !important;
  }
}

@media (max-width: 600px) {
  .book-reader-back-to-home-link > div {
    display: none;
  }
  .book-reader-back-to-home-link > svg {
    font-size: 14px !important;
    margin-right: 15px;
  }
  .book-reader-menu > div:nth-of-type(3) {
    flex-direction: row;
  }
  .book-reader-menu > div:nth-of-type(3) button{
    max-height: 100% !important;
    min-height: 100% !important;
    margin: 0 1px;
  }
}

@media (max-width: 400px) {
  .book-reader-menu > div:nth-of-type(2) > input {
    display: none;
  }
}
