.card-image-skeleton-container {
    display: flex;
    justify-content: center;
    width: 345px;
    height: 280px;
    position: relative;
    z-index: 2;
}

.card-image-skeleton-container img {
    z-index: inherit;
}

.card-image-skeleton {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: skeleton-flash 1s linear infinite alternate;
    opacity: .7;
    z-index: 1;
}

@keyframes skeleton-flash {
    0% {
        background-color: hsl(200, 20%, 70%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}