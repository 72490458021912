.mobile-drawer-dark-mode > div:nth-of-type(3) {
  background: #121212 !important;
}

.mobile-drawer-dark-mode > div:nth-of-type(3) > div div {
  border-color: #b28900 !important;
}

.mobile-drawer-dark-mode > div:nth-of-type(3) > div div h6 {
  color: #b28900 !important;
}

.mobile-drawer-dark-mode > div:nth-of-type(3) > div div p {
    color: #b28900 !important;
}

.mobile-drawer-dark-mode > div:nth-of-type(3) > div div > button {
  color: #b28900 !important;
  border-color: #b28900 !important;
}

.mobile-drawer-dark-mode > div:nth-of-type(3) > div div > a > button {
  color: black !important;
  background: #b28900 !important;
}

.mobile-drawer > div:nth-of-type(3) > div div > a > img {
    max-width: 100px;
    height: auto;
  }
